import useItem, { itemConfigs } from './useItem'
import useItemByDaypart, { itemByDaypartConfigs } from './useItemByDaypart'
import {
  itemWowGrowthByQuantityConfigs,
  itemWowGrowthBySalesConfigs,
  useBottomItemWowGrowthByQuantity,
  useBottomItemWowGrowthBySales,
  useTopItemWowGrowthByQuantity,
  useTopItemWowGrowthBySales,
} from './useItemWowGrowth'
import useLocationDayPart, {
  locationDayPartConfigs,
} from './useLocationDayPart'
import useLocationDiscountComp, {
  locationDiscountCompConfigs,
} from './useLocationDiscountComp'
import useLocationGroupDayPart, {
  locationGroupDayPartConfigs,
} from './useLocationGroupDayPart'
import useLocationGroupInventory, {
  locationGroupInventoryConfigs,
} from './useLocationGroupInventory'
import useLocationGroupRevenueCenter, {
  locationGroupRevenueCenterConfigs,
} from './useLocationGroupRevenueCenter'
import useLocationGroups, { locationGroupsConfigs } from './useLocationGroups'
import useLocationLaborOverview, {
  locationLaborOverviewConfigs,
} from './useLocationLaborOverview'
import useLocationPurchase, {
  locationPurchaseConfigs,
} from './useLocationPurchase'
import useLocationRevenueCenter, {
  locationRevenueCenterConfigs,
} from './useLocationRevenueCenter'
import useLocations, { locationsConfigs } from './useLocations'
import useMadeFromScratch, {
  madeFromScratchConfigs,
} from './useMadeFromScratch'
import useMadeFromScratchSummary, {
  madeFromScratchSummaryConfigs,
} from './useMadeFromScratchSummary'

export const configs = {
  locationGroups: locationGroupsConfigs,
  locations: locationsConfigs,
  itemByDaypart: itemByDaypartConfigs,
  madeFromScratch: madeFromScratchConfigs,
  madeFromScratchSummary: madeFromScratchSummaryConfigs,
  locationGroupDayPart: locationGroupDayPartConfigs,
  locationGroupRevenueCenter: locationGroupRevenueCenterConfigs,
  topItemWowGrowthByQuantity: itemWowGrowthByQuantityConfigs,
  bottomItemWowGrowthByQuantity: itemWowGrowthByQuantityConfigs,
  topItemWowGrowthBySales: itemWowGrowthBySalesConfigs,
  bottomItemWowGrowthBySales: itemWowGrowthBySalesConfigs,
  item: itemConfigs,
  locationDayPart: locationDayPartConfigs,
  locationRevenueCenter: locationRevenueCenterConfigs,
  locationDiscountComp: locationDiscountCompConfigs,
  locationLaborOverview: locationLaborOverviewConfigs,
  locationGroupInventory: locationGroupInventoryConfigs,
  locationPurchase: locationPurchaseConfigs,
}

export const api = {
  locationGroups: useLocationGroups,
  locations: useLocations,
  itemByDaypart: useItemByDaypart,
  madeFromScratch: useMadeFromScratch,
  madeFromScratchSummary: useMadeFromScratchSummary,
  locationGroupDayPart: useLocationGroupDayPart,
  locationGroupRevenueCenter: useLocationGroupRevenueCenter,
  topItemWowGrowthByQuantity: useTopItemWowGrowthByQuantity,
  bottomItemWowGrowthByQuantity: useBottomItemWowGrowthByQuantity,
  topItemWowGrowthBySales: useTopItemWowGrowthBySales,
  bottomItemWowGrowthBySales: useBottomItemWowGrowthBySales,
  item: useItem,
  locationDayPart: useLocationDayPart,
  locationRevenueCenter: useLocationRevenueCenter,
  locationDiscountComp: useLocationDiscountComp,
  locationLaborOverview: useLocationLaborOverview,
  locationGroupInventory: useLocationGroupInventory,
  locationPurchase: useLocationPurchase,
}
