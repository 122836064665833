import useListLocationGuestCommentData, {
  fwWingstopListLocationGuestCommentDataConfigs,
} from './guest/useListLocationGuestCommentData'
import useListEmployeeDiscountData, {
  fwWingstopListEmployeeDiscountConfigs,
} from './lossPrevention/useListEmployeeDiscountData'
import useListEmployeeLossPreventionData, {
  fwWingstopListEmployeeLossPreventionConfigs,
} from './lossPrevention/useListEmployeeLossPreventionData'
import useFwWingstopLossPreventionOutliers, {
  fwWingstopLossPreventionOutliersConfigs,
} from './lossPrevention/useListEmployeeOutliers'
import useLocationOpsCompsBreakdownData, {
  fwWingstopLocationOpsCompsBreakdownDataConfigs,
} from './lossPrevention/useLocationOpsCompsBreakdownData'
import useFwWingstopLossPrevention, {
  fwWingstopLossPreventionConfigs,
} from './lossPrevention/useLossPrevention'
import useLastTwentyEightDaysTrendData, {
  fwWingstopLastTwentyEightDaysTrendDataConfigs,
} from './useLastTwentyEightDaysTrendData'
import useFwWingstopSystemwideOverview, {
  fwWingstopSystemwideOverviewConfigs,
} from './useSystemwideOverview'
import useTimeClock, { fwWingstopTimeClockConfigs } from './useTimeClock'

export const configs = {
  fwWingstopTimeClock: fwWingstopTimeClockConfigs,
  fwWingstopLastTwentyEightDaysTrendData:
    fwWingstopLastTwentyEightDaysTrendDataConfigs,
  fwWingstopLossPrevention: fwWingstopLossPreventionConfigs,
  fwWingstopLocationOpsCompsBreakdownData:
    fwWingstopLocationOpsCompsBreakdownDataConfigs,
  fwWingstopListEmployeeLossPrevention:
    fwWingstopListEmployeeLossPreventionConfigs,
  fwWingstopListEmployeeDiscount: fwWingstopListEmployeeDiscountConfigs,
  fwWingstopListLocationGuestCommentData:
    fwWingstopListLocationGuestCommentDataConfigs,
  fwWingstopSystemwideOverview: fwWingstopSystemwideOverviewConfigs,
  fwWingstopLossPreventionOutliers: fwWingstopLossPreventionOutliersConfigs,
}

export const api = {
  fwWingstopTimeClock: useTimeClock,
  fwWingstopLastTwentyEightDaysTrendData: useLastTwentyEightDaysTrendData,
  fwWingstopLossPrevention: useFwWingstopLossPrevention,
  fwWingstopLocationOpsCompsBreakdownData: useLocationOpsCompsBreakdownData,
  fwWingstopListEmployeeLossPrevention: useListEmployeeLossPreventionData,
  fwWingstopListEmployeeDiscount: useListEmployeeDiscountData,
  fwWingstopListLocationGuestCommentData: useListLocationGuestCommentData,
  fwWingstopSystemwideOverview: useFwWingstopSystemwideOverview,
  fwWingstopLossPreventionOutliers: useFwWingstopLossPreventionOutliers,
}
