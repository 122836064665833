import FormControl from '@material-ui/core/FormControl'
import TextField from '@mui/material/TextField'
import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { useVariables } from '../variables'

export interface IPropsType {
  type: 'input'
  value: string
  onChange: string
  label?: string
  placeholder?: string
  width?: string
}

const StyledFormControl = styled(FormControl)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 25px;
`

const Label = styled.div`
  display: inline-block;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

const StyledTextField = styled(TextField)<{ width: string | undefined }>`
  min-width: 150px;
  width: ${({ width }) => width || '150px'};

  .MuiInputBase-root {
    font-family: Lexend-Regular;
    border-radius: 0;
    cursor: default;

    input {
      cursor: default;
    }
  }
`

interface IInputType {
  value: string
  onChange: (value: IInputType['value']) => void
}

const Input = ({ label, placeholder, width, ...props }: IPropsType) => {
  const { variables } = useVariables()
  const { value, onChange } = useMemo(
    () => ({
      value: _.get(variables, props.value),
      onChange: _.get(variables, props.onChange) as IInputType['onChange'],
    }),
    [props, variables],
  )

  return (
    <StyledFormControl variant="outlined" margin="dense">
      {!label ? null : <Label>{label}</Label>}

      <StyledTextField
        value={value}
        placeholder={placeholder || ''}
        onChange={(e) => onChange(e.target.value)}
        width={width}
        variant="outlined"
        size="small"
      />
    </StyledFormControl>
  )
}

export default React.memo(Input) as unknown as typeof Input
