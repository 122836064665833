import { corporateGroupTableConfigs } from '../../../variables'
import buildItemMetricValuesHook from '../utils/buildItemMetricValuesHook'

const commonInventoryConfigs = {
  itemName: 'string',
  displayParentCategoryName: 'string',
  locationId: 'string',
  actualCogs: 'price',
  theoreticalCogs: 'price',
  totalNetSales: 'price',
  actualCogsPercent: 'percent',
  theoreticalCogsPercent: 'percent',
  cogsVariance: 'price',
  cogsVariancePercent: 'percent',
} as const

export const locationGroupInventoryConfigs = {
  ...corporateGroupTableConfigs,
  ...commonInventoryConfigs,
}

const metricCodes = [
  'actual_cogs',
  'theoretical_cogs',
  'total_net_sales',
  'actual_cogs_percent',
  'theoretical_cogs_percent',
  'cogs_variance',
  'cogs_variance_percent',
]

const useLocationGroupInventory = buildItemMetricValuesHook({
  metrics: metricCodes,
  hasSummary: true,
  includeLocations: true,
})

export default useLocationGroupInventory
