import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { DATE_DATABASE_FORMAT } from 'pared/constants'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

const query = gql`
  query ListFarwestWeekTrendData(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $hasGroupBy: Boolean!
  ) {
    trendLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: "business_week"
      iFilter: $iFilter
    ) @skip(if: $hasGroupBy) {
      nodes {
        locationGroupId
        startDate
        metricData
        metricSummaryData
      }
    }

    trendLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: "business_week"
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        locationId
        startDate
        metricData
        metricSummaryData
      }
    }

    listLocationMarketAndOpenDate(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        locationId
        openedAt
        market
      }
    }
  }
`

export const farwestFourWeekTrendDataConfigs = {
  market: 'string',
  currentWeekEndDate: 'date-string',

  //flash_discounts_and_voids
  discountPercentageWeek1InPast: 'percent',
  discountPercentageWeek2InPast: 'percent',
  discountPercentageWeek3InPast: 'percent',
  discountPercentageWeek4InPast: 'percent',

  voidPercentageWeek1InPast: 'percent',
  voidPercentageWeek2InPast: 'percent',
  voidPercentageWeek3InPast: 'percent',
  voidPercentageWeek4InPast: 'percent',

  //flash_food_cost
  actualFoodCostPercentageWeek1InPast: 'percent',
  actualFoodCostPercentageWeek2InPast: 'percent',
  actualFoodCostPercentageWeek3InPast: 'percent',
  actualFoodCostPercentageWeek4InPast: 'percent',

  theoryFoodCostPercentageWeek1InPast: 'percent',
  theoryFoodCostPercentageWeek2InPast: 'percent',
  theoryFoodCostPercentageWeek3InPast: 'percent',
  theoryFoodCostPercentageWeek4InPast: 'percent',

  varFoodCostPercentageWeek1InPast: 'percent',
  varFoodCostPercentageWeek2InPast: 'percent',
  varFoodCostPercentageWeek3InPast: 'percent',
  varFoodCostPercentageWeek4InPast: 'percent',
} as const

const KPIS = [
  //flash_discounts_and_voids
  'discount_percentage',
  'void_percentage',

  //flash_food_cost
  'actual_food_cost_percentage',
  'theory_food_cost_percentage',
  'var_food_cost_percentage',
]

const useFarwestFourWeekTrendData = () => {
  const { endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()

  const momentEndDate = moment.utc(endDate, DATE_DATABASE_FORMAT, true)
  const startDate = momentEndDate
    .clone()
    .subtract(27, 'days')
    .format(DATE_DATABASE_FORMAT)

  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_group_ids: hasGroupBy
          ? groupFilter?.ids
          : groupFilter?.list?.map((g) => g.id),
        intersected_location_group_ids: groupFilter?.intersectedIds,
        metrics: KPIS,
      },
      hasGroupBy,
    },
    skip: !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      const dateToWeekMap: { [dateStr: string]: string } = {}

      for (let di = 0; di < 28; ++di) {
        const dateStr = momentEndDate
          .clone()
          .subtract(di, 'days')
          .format(DATE_DATABASE_FORMAT)
        dateToWeekMap[dateStr] = `Week${Math.floor(di / 7.0) + 1}InPast`
      }

      const currentWeekEndDate = momentEndDate.format(DATE_DATABASE_FORMAT)

      const customizedData: any =
        data?.[
          hasGroupBy
            ? 'trendLocationMetricValues'
            : 'trendLocationGroupMetricValues'
        ]?.nodes
      const locationDetails: any = data?.listLocationMarketAndOpenDate?.nodes

      if (!customizedData) return null

      const idToSourceDataMap: { [id: number]: any } = {}
      const summary: { [key: string]: number } = {}

      customizedData.forEach((locationData: any) => {
        const weekStr = dateToWeekMap[locationData.startDate]

        const groupInfo = groupFilter?.list?.find(
          (l) =>
            l.id ===
            (hasGroupBy
              ? locationData.locationId
              : locationData.locationGroupId),
        )

        if (!groupInfo?.id) {
          return
        }

        let existingData: any = idToSourceDataMap[groupInfo.id]
        if (!existingData) {
          const marketDetail = locationDetails?.find(
            (l: any) => l.locationId === locationData.locationId,
          )

          existingData = {
            groupInfo,
            currentWeekEndDate,
            openAt: marketDetail?.openedAt,
            market: marketDetail?.market,
          }
        }

        for (const key in locationData.metricData) {
          if (locationData.metricData.hasOwnProperty(key)) {
            let newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
            newKey = `${newKey}${weekStr}`

            switch (locationData.metricData[key].unit) {
              case 'DOLLAR':
              case 'PERCENTAGE':
                existingData[newKey] = locationData.metricData[key].value * 100
                break

              default:
                existingData[newKey] = locationData.metricData[key].value
                break
            }
          }
        }

        if (locationData.metricSummaryData) {
          const metricSummaryData = locationData.metricSummaryData

          for (const key in metricSummaryData) {
            if (metricSummaryData.hasOwnProperty(key)) {
              let newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
              newKey = `${newKey}${weekStr}`

              switch (metricSummaryData[key].unit) {
                case 'DOLLAR':
                case 'PERCENTAGE':
                  summary[newKey] = metricSummaryData[key].value * 100
                  break

                default:
                  summary[newKey] = metricSummaryData[key].value
                  break
              }
            }
          }
        }

        idToSourceDataMap[groupInfo.id] = existingData
      })

      return {
        source: Object.values(idToSourceDataMap),
        summary: {
          ...summary,
          currentWeekEndDate,
          openAt: '',
          market: '',
        },
      }
    }, [groupFilter, data]),
    loading,
  }
}

export default useFarwestFourWeekTrendData
