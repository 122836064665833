import { useMemo, useState } from 'react'

export interface IDataType {
  inputValue?: {
    value: string
  }
}

const useInputValue = (): IDataType => {
  const inputHook = useState('')

  return {
    inputValue: useMemo(() => {
      return {
        value: inputHook[0],
        onChange: inputHook[1],
      }
    }, [inputHook]),
  }
}

export default useInputValue
