import { IConfigsType } from '../../types'

const irmgBkEodReport: IConfigsType = {
  irmg_bk: {
    '/:brand/eod_report': {
      reportTitle: {
        type: 'title',
        title: 'Trailing 7 Days EOD Report',
        subTitle: ['Updated Daily'],
      },
      eodTable: {
        type: 'table-v2',
        api: 'irmgBkTrendEodCompleted',
        columns: [
          {
            key: 'date',
            title: 'Date',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'eodNotCompleted',
            title: 'EOD Not Completed',
            width: '600px',
            align: 'left',
          },
        ],
      },
    },
  },
}

export default irmgBkEodReport
