import useListEmployeeDeliveryData, {
  burgerworksListEmployeeDeliveryConfigs,
} from './delivery/useListEmployeeDeliveryData'
import useListLocationDeliveryDayTrendsData, {
  burgerworksListLocationDeliveryDayTrendsDataConfigs,
} from './delivery/useListLocationDeliveryDayTrendsData'
import useBurgerworkListLocationGroupDeliveryData, {
  burgerworksListLocationGroupDeliveryDataConfigs,
} from './delivery/useListLocationGroupDeliveryData'
import useBurgerworkListLocationGroupGuestData, {
  burgerworksListLocationGroupGuestDataConfigs,
} from './guest/useListLocationGroupGuestData'
import useListLocationGuestCommentData, {
  burgerworksListLocationGuestCommentDataConfigs,
} from './guest/useListLocationGuestCommentData'
import useListEmployeeDiscountData, {
  burgerworksListEmployeeDiscountConfigs,
} from './lossPrevention/useListEmployeeDiscountData'
import useListEmployeeLossPreventionData, {
  burgerworksListEmployeeLossPreventionConfigs,
} from './lossPrevention/useListEmployeeLossPreventionData'
import useLocationOpsCompsBreakdownData, {
  burgerworksLocationOpsCompsBreakdownDataConfigs,
} from './lossPrevention/useLocationOpsCompsBreakdownData'
import useBurgerworksLossPrevention, {
  burgerworksLossPreventionConfigs,
} from './lossPrevention/useLossPrevention'
import useBurgerworksCorporateSalesmanship, {
  burgerworksCorporateSalesmanshipConfigs,
} from './salesmanship/useCorporateSalesmanship'
import useListEmployeeSalesmanshipDataData, {
  burgerworksListEmployeeSalesmanshipDataConfigs,
} from './salesmanship/useListEmployeeSalesmanshipData'
import useListManagerOnDutyData, {
  burgerworksListManagerOnDutyDataConfigs,
} from './salesmanship/useListManagerOnDutyData'
import useBurgerworksFlash, { burgerworksFlashConfigs } from './useFlash'
import useLocationSalesByOrigin, {
  locationSalesByOriginConfigs,
} from './useLocationSalesByOrigin'
import useBurgerworksRankings, {
  burgerworksRankingsConfigs,
} from './useRankings'
import useBurgerworksStorePerformance, {
  burgerworksStorePerformanceConfigs,
} from './useStorePerformance'
import useBurgerworksSystemwideOverview, {
  burgerworksSystemwideOverviewConfigs,
} from './useSystemwideOverview'

export const configs = {
  burgerworksLossPrevention: burgerworksLossPreventionConfigs,
  burgerworksListEmployeeDiscount: burgerworksListEmployeeDiscountConfigs,
  burgerworksLocationOpsCompsBreakdownData:
    burgerworksLocationOpsCompsBreakdownDataConfigs,
  burgerworksListEmployeeLossPrevention:
    burgerworksListEmployeeLossPreventionConfigs,
  burgerworksSystemwideOverview: burgerworksSystemwideOverviewConfigs,
  burgerworksFlash: burgerworksFlashConfigs,
  burgerworkListLocationGroupDelivery:
    burgerworksListLocationGroupDeliveryDataConfigs,
  burgerworksStorePerformance: burgerworksStorePerformanceConfigs,
  burgerworksCorporateSalesmanship: burgerworksCorporateSalesmanshipConfigs,
  burgerworksListEmployeeSalesmanshipData:
    burgerworksListEmployeeSalesmanshipDataConfigs,
  burgerworksListEmployeeDeliveryData: burgerworksListEmployeeDeliveryConfigs,
  burgerworksListManagerOnDutyData: burgerworksListManagerOnDutyDataConfigs,
  burgerworksListLocationDeliveryDayTrendsData:
    burgerworksListLocationDeliveryDayTrendsDataConfigs,
  burgerworkListLocationGroupGuest:
    burgerworksListLocationGroupGuestDataConfigs,
  burgerworksListLocationGuestCommentData:
    burgerworksListLocationGuestCommentDataConfigs,
  burgerworksRankings: burgerworksRankingsConfigs,
  burgerworksLocationSalesByOrigin: locationSalesByOriginConfigs,
}

export const api = {
  burgerworksLossPrevention: useBurgerworksLossPrevention,
  burgerworksListEmployeeDiscount: useListEmployeeDiscountData,
  burgerworksLocationOpsCompsBreakdownData: useLocationOpsCompsBreakdownData,
  burgerworksListEmployeeLossPrevention: useListEmployeeLossPreventionData,
  burgerworksSystemwideOverview: useBurgerworksSystemwideOverview,
  burgerworksFlash: useBurgerworksFlash,
  burgerworkListLocationGroupDelivery:
    useBurgerworkListLocationGroupDeliveryData,
  burgerworksStorePerformance: useBurgerworksStorePerformance,
  burgerworksCorporateSalesmanship: useBurgerworksCorporateSalesmanship,
  burgerworksListEmployeeSalesmanshipData: useListEmployeeSalesmanshipDataData,
  burgerworksListEmployeeDeliveryData: useListEmployeeDeliveryData,
  burgerworksListManagerOnDutyData: useListManagerOnDutyData,
  burgerworksListLocationDeliveryDayTrendsData:
    useListLocationDeliveryDayTrendsData,
  burgerworkListLocationGroupGuest: useBurgerworkListLocationGroupGuestData,
  burgerworksListLocationGuestCommentData: useListLocationGuestCommentData,
  burgerworksRankings: useBurgerworksRankings,
  burgerworksLocationSalesByOrigin: useLocationSalesByOrigin,
}
